import './App.css';
import { Route, Routes } from 'react-router-dom';
import LoginState from './context/login_context/LoginState';
import { Login } from './pages/login/Login.page';
import { Home } from './pages/session/Home.page';
import { ProtectedRouter } from './component/Protected/ProtectedRouter';

function App() {
  return (
    <LoginState>
      <Routes>
        <Route path='/' element={<Login />} />
        {/* <Route path='/recuperar_cuenta' element={<RecoveryPass />}/>
        <Route path='/recuperar_cuenta' element={<RecoveryPass />}/> */}
        
        <Route path='/home' element={
          <ProtectedRouter>
            <Home />
          </ProtectedRouter>
        } />

        {/* <Route path='/ot' element={
          <ProtectedRouter>
            <Ot />
          </ProtectedRouter>
        } /> */}

        {/* <Route path='/st' element={
          <ProtectedRouter>
            <St />
          </ProtectedRouter>
        } /> */}

        <Route path='*' element={<Login />} />
      </Routes>
    </LoginState>
  );
}

export default App;
