import React, { useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom';
import { URL_LOGIN, URL_SRV_RAIZ } from '../../router/Url';
import LoginContext from './LoginContext';
import LoginReducer from './LoginReducer'

const initialState = {
  user: "",
  planta: "",
  rut: "",
  loginDate: "",
  area: ""
}

const LoginState = (props) => {
  const [state, dispatch] = useReducer(LoginReducer, initialState);
  const navigation = useNavigate();

  useEffect(() => {
    let user = sessionStorage.getItem('user')
    let rut = sessionStorage.getItem('rut')
    let planta = sessionStorage.getItem('planta')
    let area = sessionStorage.getItem('area')
    let loginDate = sessionStorage.getItem('loginDate')
    if(user && rut && planta){
      dispatch({
        type: 'SETLOGIN',
        payload: {
          area: area,
          user: user,
          planta: planta,
          rut: rut,
          loginDate: loginDate
        }
      })
      navigation('/home')
    }
  }, [])

  // methods
  
  const SetLogin = async ({valores}) => {
    return new Promise(async(resolve, reject) => {
      let query = await fetch(URL_SRV_RAIZ+'login_encuesta',{
        method: "POST",
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          rut:valores.user.toLocaleUpperCase(),
        })
      })

      let responsio = await query.json();
      if(responsio.data.success){
        let in_login = new Date().toLocaleString()
        // console.log(responsio.data)
        sessionStorage.setItem('area',responsio.data.area)
        sessionStorage.setItem('user',responsio.data.nombre)
        sessionStorage.setItem('planta',responsio.data.empresa)
        sessionStorage.setItem('rut',responsio.data.rut)
        sessionStorage.setItem('loginDate',in_login)
        dispatch({
          type: 'SETLOGIN',
          payload: {
            area: responsio.data.area,
            user: responsio.data.nombre,
            planta: responsio.data.empresa,
            rut: responsio.data.rut,
            loginDate: in_login
          }
        })
        resolve(responsio.data)     
      }else{
        resolve(responsio.data)
      }
    })
  }

  const GetLoginSession = async () => {
    return new Promise(async (resolve, reject) => {
      const user = sessionStorage.getItem('user')
      const rut = sessionStorage.getItem('rut')

      if(user && rut) {
       
        dispatch({
          type: 'SETLOGIN',
          payload: {
            user: user,
            rut: rut,
            loginDate: new Date().toUTCString()
          }
        })
        resolve(true)

      } else {
        resolve(false)
      }

    })
  }

  const CloseLogin = async () => {
    sessionStorage.clear()
    return (dispatch({
      type: 'SETLOGIN',
      payload: {
        user: "",
        planta: "",
        token: "",
        loginDate: "",
        area: ""
      }
    }))
  }
  
  return (
    <LoginContext.Provider
      value={{
        LoginState:state,
        SetLogin,
        CloseLogin,
        GetLoginSession
      }}
    >
      {props.children}
    </LoginContext.Provider>
  )
}

export default LoginState