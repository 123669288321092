// export const URL_LOGIN = "https://apimedidores.apidev.info/signin/"
// export const URL_FORGET = "https://apimedidores.apidev.info/forgetpassword/"


/**
 * FROM LOCAL SERVER
 */
// export const URL_LOGIN =       "http://localhost:4088/signin_vr/"
// export const URL_SRV =         "http://localhost:4088/app-medidor/"
// export const URL_FORGET =      "http://localhost:4088/forgetpassword/"
// export const URL_GETPLANTA =   "http://localhost:4088/app-medidor/getlistaempresas"
// export const URL_CTRL_HIDRIC = "http://localhost:4088/ctrl-hidrico/"
// export const URL_CTRL_TRASP =  "http://localhost:4088/ctrl-transporte/"
// export const URL_SRV_RAIZ =    "http://localhost:4088/"
// export const URL_SRV_ARIZTIA = "http://localhost:4088/ariztia/"


/**
 * FROM REMOTE APISERVER
 */
export const URL_LOGIN =        "https://apimedidores.apidev.info/signin_vr/" // consulta en la base de datos de los usuarios de fracttal
export const URL_SRV =          "https://apimedidores.apidev.info/app-medidor/"
export const URL_FORGET =       "https://apimedidores.apidev.info/forgetpassword/"
export const URL_GETPLANTA =    "https://apimedidores.apidev.info/app-medidor/getlistaempresas"
export const URL_CTRL_HIDRIC =  "https://apimedidores.apidev.info/ctrl-hidrico/"
export const URL_CTRL_TRASP =   "https://apimedidores.apidev.info/ctrl-transporte/"
export const URL_SRV_RAIZ =     "https://apimedidores.apidev.info/"
export const URL_SRV_ARIZTIA =  "https://apimedidores.apidev.info/ariztia/"

export const VERSION_APP =      "versión 1.0.1"

/** 
 * ENDPOINT API INTERNA FRACTTAL
 */
export const URL_GET_OTS =      "http://10.30.11.20:4082/api/fracttal/getwofolio?code_user="
export const URL_GET_TASKS =    "http://10.30.11.20:4082/api/fracttal/gettasks?wo_folio="

