import React, { useContext, useState } from 'react'
import { HeaderMenu } from '../../component/HeaderMenu'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import LoginContext from '../../context/login_context/LoginContext'
import { URL_SRV_RAIZ } from '../../router/Url'

const userList = []

const userList_1 = [
  "ROJAS TRONCOSO DAVID GAMALIER",
"HUERTA GUTIERREZ PEDRO AGUSTIN",
"CARRENO LIZANA EMILIO ANDRES",
"CARVAJAL CEPEDA MICHEL HERNAN",
"GONZALEZ MELO DANIEL MARCELO",
"DONOSO OLGUIN CRISTOPHER RICARDO",

]
const userList_2 = [
  "ALVAREZ ARELLANO MATIAS JOSE",
"ARAYA ORELLANA GABRIEL ALEJANDRO",
"ARMIJO RIVERA CLAUDIO MARCELO",
"BARRERA NOVOA ARIEL ANDRES",
"CACERES HENRIQUEZ ELIAS LUCIANO",
"CARRENO LIZANA EMILIO ANDRES",
"CASTILLO TRIVINO PABLO IGNACIO",
"CASTRO GUZMAN ROBERTO MARCELO",
"CORDOVA GONZALEZ ABRAHAM HIDEKEL",
"CORDOVA VARGAS JEREMY NICOLAS",
"DIAZ ROSALES CARLOS IGNACIO",
"DONOSO OLGUIN CRISTOPHER RICARDO",
"FUENTES ARENAS MAURICIO IGNACIO",
"GALLEGUILLOS IRRAZABAL CARLOS PATRICIO",
"GONZALEZ MELO DANIEL MARCELO",
"GONZALEZ PEREZ CRISTOBAL NICOLAS",
"GUAJARDO VERA SEBASTIAN ERASMO",
"GUTIERREZ LOPEZ PEDRO ALEJANDRO",
"HUERTA GUTIERREZ PEDRO AGUSTIN",
"LOPEZ MANCILLA BENJAMIN ALFREDO",
"MALAGUENO VILLALOBOS PABLO",
"MATUS ARMIJO DANIEL IGNACIO",
"MELILLAN VALENZUELA ANDRES IGNACIO",
"MELLADO DELGADO EMMANUEL IGNACIO",
"OSORIO SAN MARTIN FACUNDO ANDRES",
"ROBERTS SOTO ANGELO ISRAEL",
"RODRIGUEZ ESPINOZA LUIS MIGUEL",
"ROJAS TRONCOSO DAVID GAMALIER",
"SANTIS SILVA ISMAEL ALEJANDRO",
"SILVA CARVAJAL DIEGO ALEJANDRO",
"TRUJILLO ELGUETA KEVYN ESTEBAN",
"URBINA SALAS BRIAN ANDRES",
"VARAS BALBI CRISTIAN LEONARDO",
"VEGA PADILLA IGNACIO NICOLAS",
"VERA GUZMAN JUAN ALFREDO",
"VERA ROJAS IGNACIO JOSE",
"VERDEJO MUNOZ MATIAS ELEAZAR",
"ZUNIGA VILLAR JEAN CARLOS",
]
const userList_3 = [
  "ALVAREZ ARELLANO MATIAS JOSE",
"GONZALEZ PEREZ CRISTOBAL NICOLAS",
"GUTIERREZ LOPEZ PEDRO ALEJANDRO",
"MELLADO DELGADO EMMANUEL IGNACIO",
"URRUTIA MOLINA MARCO ANTONIO",

]
const userList_4 = [
  "ALVAREZ ARELLANO MATIAS JOSE",
"DIAZ ROSALES CARLOS IGNACIO",
"DONOSO OLGUIN CRISTOPHER RICARDO",
"FUENTES ARENAS MAURICIO IGNACIO",
"GONZALEZ FLORES PEDRO PABLO",
"LOPEZ MANCILLA BENJAMIN ALFREDO",
"MALAGUENO VILLALOBOS PABLO",
"OSORIO SAN MARTIN FACUNDO ANDRES",
"SALAS VARGAS MIGUEL ANGEL",
"TRUJILLO ELGUETA KEVYN ESTEBAN",
"URBINA SALAS BRIAN ANDRES",
"VEGA PADILLA IGNACIO NICOLAS",
"VERDEJO MUNOZ MATIAS ELEAZAR",

]
const userList_5 = [
  "DONOSO OLGUIN CRISTOPHER RICARDO",
"FUENTES ARENAS MAURICIO IGNACIO",
"OSORIO SAN MARTIN FACUNDO ANDRES",
"ROJAS TRONCOSO DAVID GAMALIER",
"CASTILLO TRIVINO PABLO IGNACIO",
]
const userList_6 = [
  "MALAGUENO VILLALOBOS PABLO",
"MATURANA QUINTANILLA VANESA ESTEFANI",
"MELILLAN VALENZUELA ANDRES IGNACIO",
"SILVA CARVAJAL DIEGO ALEJANDRO",
"VEGA PADILLA IGNACIO NICOLAS",

]
const userList_7 = [
  "ALFARO CORREA MAURICIO DARIO",
"ALVAREZ ARELLANO MATIAS JOSE",
"ARAYA ORELLANA GABRIEL ALEJANDRO",
"ARMIJO RIVERA CLAUDIO MARCELO",
"BARRERA NOVOA ARIEL ANDRES",
"CACERES HENRIQUEZ ELIAS LUCIANO",
"CARRENO LIZANA EMILIO ANDRES",
"CARVAJAL CEPEDA MICHEL HERNAN",
"CASTILLO TRIVINO PABLO IGNACIO",
"CASTRO GUZMAN ROBERTO MARCELO",
"CHACON MARTINEZ CATALINA DEL CARMEN",
"CORDOVA GONZALEZ ABRAHAM HIDEKEL",
"CORDOVA VARGAS JEREMY NICOLAS",
"DIAZ ROSALES CARLOS IGNACIO",
"DONOSO OLGUIN CRISTOPHER RICARDO",
"FUENTES ARENAS MAURICIO IGNACIO",
"GALLEGUILLOS IRRAZABAL CARLOS PATRICIO",
"GONZALEZ FLORES PEDRO PABLO",
"GONZALEZ MELO DANIEL MARCELO",
"GONZALEZ PEREZ CRISTOBAL NICOLAS",
"GUAJARDO VERA SEBASTIAN ERASMO",
"GUTIERREZ LOPEZ PEDRO ALEJANDRO",
"HUERTA GUTIERREZ PEDRO AGUSTIN",
"LOPEZ MANCILLA BENJAMIN ALFREDO",
"MALAGUENO VILLALOBOS PABLO",
"MATURANA QUINTANILLA VANESA ESTEFANI",
"MATUS ARMIJO DANIEL IGNACIO",
"MELILLAN VALENZUELA ANDRES IGNACIO",
"MELLADO DELGADO EMMANUEL IGNACIO",
"MESA MUNOZ CAROL VALENTINA",
"NAVARRO CORNEJO NATALIA ANDREA",
"OSORIO SAN MARTIN FACUNDO ANDRES",
"PINTO NORAMBUENA CAMILA ALEJANDRA",
"ROBERTS SOTO ANGELO ISRAEL",
"RODRIGUEZ ESPINOZA LUIS MIGUEL",
"ROJAS TRONCOSO DAVID GAMALIER",
"SAEZ MORALES KAREN VICTORIA",
"SALAS VARGAS MIGUEL ANGEL",
"SANTIS SILVA ISMAEL ALEJANDRO",
"SILVA CARVAJAL DIEGO ALEJANDRO",
"TRUJILLO ELGUETA KEVYN ESTEBAN",
"URBINA SALAS BRIAN ANDRES",
"URRUTIA MOLINA MARCO ANTONIO",
"VARAS BALBI CRISTIAN LEONARDO",
"VEGA PADILLA IGNACIO NICOLAS",
"VERA GUZMAN JUAN ALFREDO",
"VERA ROJAS IGNACIO JOSE",
"VERDEJO MUNOZ MATIAS ELEAZAR",
"ZUNIGA VILLAR JEAN CARLOS",

]
const userList_8 = [
  "ROJAS TRONCOSO DAVID GAMALIER",
"SANTIS SILVA ISMAEL ALEJANDRO",
"GUTIERREZ LOPEZ PEDRO ALEJANDRO",
]
const userList_9 = [
  "LOPEZ MANCILLA BENJAMIN ALFREDO",
"MATURANA QUINTANILLA VANESA ESTEFANI",
"SILVA CARVAJAL DIEGO ALEJANDRO",
"NAVARRO CORNEJO NATALIA ANDREA",
"CARRENO LIZANA EMILIO ANDRES",
"ROJAS TRONCOSO DAVID GAMALIER",
]
const userList_10 = [
  "CARVAJAL CEPEDA MICHEL HERNAN",
"CORDOVA GONZALEZ ABRAHAM HIDEKEL",
"DIAZ ROSALES CARLOS IGNACIO",
"DONOSO OLGUIN CRISTOPHER RICARDO",
"FUENTES ARENAS MAURICIO IGNACIO",
"GONZALEZ FLORES PEDRO PABLO",
"HUERTA GUTIERREZ PEDRO AGUSTIN",
"LOPEZ MANCILLA BENJAMIN ALFREDO",
"MALAGUENO VILLALOBOS PABLO",
"OSORIO SAN MARTIN FACUNDO ANDRES",
"ROBERTS SOTO ANGELO ISRAEL",
"RODRIGUEZ ESPINOZA LUIS MIGUEL",
"SILVA CARVAJAL DIEGO ALEJANDRO",
"VERA ROJAS IGNACIO JOSE",
"BARRERA NOVOA ARIEL ANDRES",
]
const userList_11 = [
  "BARRERA NOVOA ARIEL ANDRES",
"FUENTES ARENAS MAURICIO IGNACIO",
"GONZALEZ MELO DANIEL MARCELO",
"MATUS ARMIJO DANIEL IGNACIO",
"ROJAS TRONCOSO DAVID GAMALIER",
"SANTIS SILVA ISMAEL ALEJANDRO",
"CASTILLO TRIVINO PABLO IGNACIO",
]
const userList_12 = [
  "ROJAS TRONCOSO DAVID GAMALIER",
"HUERTA GUTIERREZ PEDRO AGUSTIN",
"CARRENO LIZANA EMILIO ANDRES",
"CARVAJAL CEPEDA MICHEL HERNAN",
"GONZALEZ MELO DANIEL MARCELO",
"DONOSO OLGUIN CRISTOPHER RICARDO",
"MESA MUNOZ CAROL VALENTINA",
]
const userList_13 = [
  "ALVAREZ ARELLANO MATIAS JOSE",
"ARAYA ORELLANA GABRIEL ALEJANDRO",
"ARMIJO RIVERA CLAUDIO MARCELO",
"CACERES HENRIQUEZ ELIAS LUCIANO",
"CARRENO LIZANA EMILIO ANDRES",
"CASTRO GUZMAN ROBERTO MARCELO",
"DIAZ ROSALES CARLOS IGNACIO",
"GONZALEZ FLORES PEDRO PABLO",
"GUAJARDO VERA SEBASTIAN ERASMO",
"MALAGUENO VILLALOBOS PABLO",
]
const userList_14 = [
  "ARAYA ORELLANA GABRIEL ALEJANDRO",
  "BARRERA NOVOA ARIEL ANDRES",
  "DONOSO OLGUIN CRISTOPHER RICARDO",
  "FUENTES ARENAS MAURICIO IGNACIO",
  "GUAJARDO VERA SEBASTIAN ERASMO",
  "HUERTA GUTIERREZ PEDRO AGUSTIN",
  "ROJAS TRONCOSO DAVID GAMALIER",
  "SALAS VARGAS MIGUEL ANGEL",
  "SANTIS SILVA ISMAEL ALEJANDRO",
  "VERA GUZMAN JUAN ALFREDO",
]
const userList_15 = [
  "VARAS BALBI CRISTIAN LEONARDO",
"ARMIJO RIVERA CLAUDIO MARCELO",
"GUAJARDO VERA SEBASTIAN ERASMO",

]


export const Home = () => {
  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [user, setUser] = useState(LoginState.user);
  const [Categoria_1, setCategoria_1] = useState("");
  const [Categoria_2, setCategoria_2] = useState("");
  const [Categoria_3, setCategoria_3] = useState("");
  const [Categoria_4, setCategoria_4] = useState("");
  const [Categoria_5, setCategoria_5] = useState("");
  const [Categoria_6, setCategoria_6] = useState("");
  const [Categoria_7, setCategoria_7] = useState("");
  const [Categoria_8, setCategoria_8] = useState("");
  const [Categoria_9, setCategoria_9] = useState("");
  const [Categoria_10, setCategoria_10] = useState("");
  const [Categoria_11, setCategoria_11] = useState("");
  const [Categoria_12, setCategoria_12] = useState("");
  const [Categoria_13, setCategoria_13] = useState("");
  const [Categoria_14, setCategoria_14] = useState("");
  const [Categoria_15, setCategoria_15] = useState("");

  const handleSendData = async () => {
    return new Promise(async (resolve, reject) => {
      let query = await fetch(URL_SRV_RAIZ+'guarda_dito',{
        method: 'POST',
        headers: {
          'authorization': "marcuxo",
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          user,
          Categoria_1, Categoria_2, Categoria_3, Categoria_4, Categoria_5,
          Categoria_6, Categoria_7, Categoria_8, Categoria_9, Categoria_10,
          Categoria_11,Categoria_12,Categoria_13,Categoria_14,Categoria_15
        })
      })
      let responsito = await query.json();
  
      if(responsito.data.success){
        alert("Gracias por tu votacion 😎")
        await setCategoria_1("")
        await setCategoria_2("")
        await setCategoria_3("")
        await setCategoria_4("")
        await setCategoria_5("")
        await setCategoria_6("")
        await setCategoria_7("")
        await setCategoria_8("")
        await setCategoria_9("")
        await setCategoria_10("")
        await setCategoria_11("")
        await setCategoria_12("")
        await setCategoria_13("")
        await setCategoria_14("")
        await setCategoria_15("")
      }else{
        
      }
    })
  }
  
  // return (
  //   <>
  //     <HeaderMenu Pag_Sel={'home'} />
  //     <div className='container'>
  //       <div className='row'>
  //         <div className='col-12 py-3 my-2'>
  //           las votaciones estarán habilitadas hasta el 13 de Diciembre.
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>1 EL MAS DETENIDO(<small>DETENCIONES EN PLANTA</small>)</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_1}
  //                   onChange={(e)=>setCategoria_1(e.target.value)}
  //                 >
  //                   {
  //                     userList_1.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>2 LINDO CALIENTE</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_2}
  //                   onChange={(e)=>setCategoria_2(e.target.value)}
  //                 >
  //                   {
  //                     userList_2.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>3 EL QUE ESTA PERO NO ESTA</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_3}
  //                   onChange={(e)=>setCategoria_3(e.target.value)}
  //                 >
  //                   {
  //                     userList_3.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>4 EL REY DEL PUENTE(<small>CONEXIONES ELECTRICAS</small>)</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_4}
  //                   onChange={(e)=>setCategoria_4(e.target.value)}
  //                 >
  //                   {
  //                     userList_4.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>5 EL MAS HAMBRIENTO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_5}
  //                   onChange={(e)=>setCategoria_5(e.target.value)}
  //                 >
  //                   {
  //                     userList_5.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>6 EL MAS PRENDIDO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_6}
  //                   onChange={(e)=>setCategoria_6(e.target.value)}
  //                 >
  //                   {
  //                     userList_6.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>7 EL MEJOR COMPAÑER@</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_7}
  //                   onChange={(e)=>setCategoria_7(e.target.value)}
  //                 >
  //                   {
  //                     userList_7.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    
  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>8 EL MAS CREATIVO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_8}
  //                   onChange={(e)=>setCategoria_8(e.target.value)}
  //                 >
  //                   {
  //                     userList_8.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>9 EL MAS DIVERTIDO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_9}
  //                   onChange={(e)=>setCategoria_9(e.target.value)}
  //                 >
  //                   {
  //                     userList_9.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>
  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>10 EL MEJOR VESTIDO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_10}
  //                   onChange={(e)=>setCategoria_10(e.target.value)}
  //                 >
  //                   {
  //                     userList_10.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>11 EL MAS CALLAMPEADO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_11}
  //                   onChange={(e)=>setCategoria_11(e.target.value)}
  //                 >
  //                   {
  //                     userList_11.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>12 EL COORDINADOR MAS DESCORDINADO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_12}
  //                   onChange={(e)=>setCategoria_12(e.target.value)}
  //                 >
  //                   {
  //                     userList_12.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>

  //         {/* <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>13 EL MAS COLABORATIVO DEL TURNO</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_13}
  //                   onChange={(e)=>setCategoria_13(e.target.value)}
  //                 >
  //                   {
  //                     userList_13.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div> */}

  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>14 EL MAS ENOJON(A)</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_14}
  //                   onChange={(e)=>setCategoria_14(e.target.value)}
  //                 >
  //                   {
  //                     userList_14.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='col-12 py-3 my-2' style={{border: '1px solid gray', borderRadius:10}}>
  //           <div className='row'>
  //             <div className='col-12 col-md-6'>15 EL MAS COLABORATIVO DEL TURNO(<small>SELECCIONADO POR LOS COORDINADORES</small>)</div>
  //             <div className='col-12 col-md-6'>
  //               <FormControl fullWidth>
  //                 <InputLabel id="demo-simple-select-label" size='small'>Postulante</InputLabel>
  //                 <Select
  //                   size='small'
  //                   labelId="demo-simple-select-label"
  //                   id="demo-simple-select"
  //                   label="Postulante"
  //                   value={Categoria_15}
  //                   onChange={(e)=>setCategoria_15(e.target.value)}
  //                 >
  //                   {
  //                     userList_15.map((usr, index)=><MenuItem key={index} value={usr}>{usr}</MenuItem>)
  //                   }
                    

  //                 </Select>
  //               </FormControl>
  //             </div>
  //           </div>
  //         </div>

  //         <div className='col-12 py-3 my-2 text-center'>
  //           <button className='btn btn-success w-50' onClick={()=>handleSendData()}>Enviar</button>
  //         </div>

  //         <div className='col-12 py-3 my-2 text-center'>
  //           <small><b>Organizador: KarenSaez producciones</b></small>
  //         </div>
          
  //       </div>
  //     </div>
  //   </>
  // )

  return (
    <>
      <HeaderMenu Pag_Sel={'home'} />
      <div className='container mt-5'>
        <div className='row'>
          <div className='col text-center'>
            <p className='display-4'>Las Votaciones han finalizado 😎</p>
            
          </div>
        </div>
      </div>
    </>
  )
}
