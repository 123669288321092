import React, { isValidElement, useContext, useLayoutEffect, useState } from 'react'
import { Button, FormControl, IconButton, Input, InputAdornment, InputLabel, Tooltip, Zoom } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ContactSupportTwoToneIcon from '@mui/icons-material/ContactSupportTwoTone';
import AccountCircle from '@mui/icons-material/AccountCircle'
import PasswordIcon from '@mui/icons-material/Password';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useNavigate } from 'react-router-dom';
import LoginContext from '../../context/login_context/LoginContext';
import { VERSION_APP } from '../../router/Url';

export const Login = () => {
  
  const navigate = useNavigate();

  const {LoginState,SetLogin,CloseLogin,GetLoginSession} = useContext(LoginContext);
  const [IsValidate, setIsValidate] = useState(true)
  

  useLayoutEffect(() => {
    // if(LoginState.token!=='NO-TOKEN')navigate('/dashboard')
  }, [LoginState])
  

  const [valores, setValores] = useState({
    user:'',
    password:'',
    showPassword: false,
    isSubmit: false,
    errorMessage: null
  })

  const HandleSetData = async (e) => {
    let a = e.target.value.trim()
    let d = a.split('-').join('')
    // console.log(d)
    if(d.length<=9){
      let b = d.slice(d.length-1)
      let c = d.slice(0,d.length-1)
      // console.log(d.length,c,"-",b)
      setValores({...valores,[e.target.name]:c+"-"+b});
    }

    if(d.length>=8){
      setIsValidate(false)
    }else{
      setIsValidate(true)
    }
    // console.log(IsValidate)
  }

  // show or hide pass input
  const handleClickShowPassword = () => {
    setValores({
      ...valores,
      showPassword: !valores.showPassword,
    });
  };
  
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // verify login
  const HandleSubmit = async () => {
    const isLogin = await SetLogin({valores})
    // console.log(isLogin)
    if(isLogin.success){
      navigate('./home')//ingresar_transporte
    }else{
      setValores({...valores,errorMessage:isLogin.error})
    }
  }

  return (
    <>
      <div className="container-fluid bg-ariztia">
        <div className="row" style={{height: '100vh'}}>
          <div className="col-12 col-md-6 d-flex align-items-center text-center justify-content-center">
            <img src="/img/logo_ariztia.png" alt="Ariztia" />
          </div>
          <div className="col-12 col-md-5 px-3 fondo d-flex align-items-center text-center justify-content-center performance-2-2" >
            <div className="row">
              <div className="col-12 pt-3 text-left">
                <h4>Inicio</h4>
              </div>
              <div className="col-12 pt-3">
                <FormControl fullWidth variant="standard">
                  <InputLabel htmlFor="input-username">
                    Rut
                  </InputLabel>
                  <Input
                    type="text"
                    name='user'
                    value={valores.user}
                    onChange={(e)=>HandleSetData(e)}
                    startAdornment={
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="col-12 pt-3">
              
              </div>
              <div className="col-12">
                {valores.errorMessage?
                <small className="text-danger">{valores.errorMessage}</small>
                :null
                }
              </div>
              <div className="col-12 pt-5 text-right">
                <Button
                  disabled={IsValidate}
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={()=>HandleSubmit()}
                  endIcon={<AssignmentIndIcon />} >Entrar</Button>
              </div>
              <div className='col-12 alfondo'>
                <div className='row'>
                  <div className='col text-right'>
                   <span className='version_lg'>{VERSION_APP}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
